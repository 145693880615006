import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation, Navigate, useNavigate } from 'react-router-dom';
import './styles.css';
import Footer from './Footer';
import axios from 'axios';
import Contact from './component/Contact';
import { HelmetProvider } from 'react-helmet-async';
import { API_URL } from './config';
const Base = React.lazy(() => import('./Base'));
const Logo = React.lazy(() => import('./fetch/Logo'));
const All_Cat = React.lazy(() => import('./fetch/Category'));
const Service = React.lazy(() => import('./fetch/Service'));
const Slides = React.lazy(() => import('./fetch/Slides'));
const Teams = React.lazy(() => import('./fetch/Team'));
const Paid = React.lazy(() => import('./fetch/Paid'));
const Hired = React.lazy(() => import('./fetch/Hired'));
const Purchase = React.lazy(() => import('./fetch/Purchase'));
const Working = React.lazy(() => import('./fetch/Working'));
const Rate = React.lazy(() => import('./fetch/Rate'));
const Tickets = React.lazy(() => import('./fetch/Tickets'));
const Ticket = React.lazy(() => import('./fetch/Ticket'));
const MilesTones = React.lazy(() => import('./fetch/MilesTones'));
const Exchange = React.lazy(() => import('./fetch/Exchange'));
const NewWork = React.lazy(() => import('./fetch/NewWork'));
const MakeMoney = React.lazy(() => import('./fetch/MakeMoney'));
const UserMiles = React.lazy(() => import('./fetch/UserMiles'));
const Users = React.lazy(() => import('./fetch/Users'));
const Make = React.lazy(() => import('./fetch/Make'));
const Header = React.lazy(() => import('./component/Header'));
const SignupForm = React.lazy(() => import('./SignupForm'));
const Main = React.lazy(() => import('./component/Main'));
const Login = React.lazy(() => import('./component/Login'));
const AboutUs = React.lazy(() => import('./component/About'));
const PrivacyPolicy = React.lazy(() => import('./component/PrivacyPolicy'));
const RefundPolicy = React.lazy(() => import('./component/RefundPolicy'));
const TermsConditions = React.lazy(() => import('./component/TermsConditions'));

const Dashboard = React.lazy(() => import('./Dashboard'));

function getSessionKeyFromCookie(cookieName) {
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith(`${cookieName}=`)) {
      return cookie.substring(cookieName.length + 1);
    }
  }
  return null;
}
const NotFound = () => (
  <div className='col-10 m-auto text-center pb-8 pt-8 text-light'>
    <h1>404 - Not Found</h1>
    <p>The page you are looking for does not exist.</p>
  </div>
);
const App = () => (
  <HelmetProvider>
  <Router>
    <AppContent />
  </Router>
</HelmetProvider>
);

const AppContent = () => {
  const [loading, setLoading] = useState(true); 
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const isDashboardRoute = location.pathname.startsWith('/dashboard/');
  const Key = getSessionKeyFromCookie('session_key');
  const headers = {
    'Authorization': `${Key}`
  };
  
  useEffect(() => {
    const fetchData = async () => {
      if (Key) {
        try {
          const response = await axios.get(`${API_URL}/check/authentication/`, {
            headers: headers
          });
          setIsAuthenticated(response.data.is_authenticated);
        } catch (error) {
          setIsAuthenticated(false);
        } finally {
          setLoading(false); 
        }
      } else {
        setIsAuthenticated(false);
        setLoading(false);
      }
    };

    fetchData();
  }, [Key]);

  useEffect(() => {
    const handleRouteChange = () => {
      setLoading(true);
      setTimeout(() => setLoading(false), 0);
    };

    const handlePopState = () => handleRouteChange();
    window.addEventListener('popstate', handlePopState);

    return () => window.removeEventListener('popstate', handlePopState);
  }, []);

  useEffect(() => {
    if (isAuthenticated && (location.pathname === '/signup' || location.pathname === '/login')) {
      navigate('/dashboard/', { replace: true });
    }
  }, [isAuthenticated, navigate, location.pathname]);
  if (loading) {
    return <div className="progress-bar"></div>;
  }

  

  return (
    <>
      <React.Suspense fallback={<div className="progress-bar"></div>}>
        {!isDashboardRoute && <Header />}
      </React.Suspense>
      <Routes>
        {isAuthenticated && (
          <Route path="/dashboard/*" element={<React.Suspense fallback={<div className="progress-bar"></div>}><DashboardLayout /></React.Suspense>} />
        )}
        <Route path="/signup" element={<React.Suspense fallback={<div className="progress-bar"></div>}><SignupForm /></React.Suspense>} />
        <Route path="/login" element={<React.Suspense fallback={<div className="progress-bar"></div>}><Login /></React.Suspense>} />
        <Route path="/contact" element={<React.Suspense fallback={<div className="progress-bar"></div>}><Contact /></React.Suspense>} />
        <Route path="/about-us" element={<React.Suspense fallback={<div className="progress-bar"></div>}><AboutUs /></React.Suspense>} />
        <Route path="/privacy-and-policy" element={<React.Suspense fallback={<div className="progress-bar"></div>}><PrivacyPolicy /></React.Suspense>} />
        <Route path="/refund-policy" element={<React.Suspense fallback={<div className="progress-bar"></div>}><RefundPolicy /></React.Suspense>} />
        <Route path="/terms-and-conditions" element={<React.Suspense fallback={<div className="progress-bar"></div>}><TermsConditions /></React.Suspense>} />
        <Route path="/" element={<React.Suspense fallback={<div className="progress-bar"></div>}><Main /></React.Suspense>} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <React.Suspense fallback={<div className="progress-bar"></div>}>
        {!isDashboardRoute && <Footer />}
      </React.Suspense>
    </>
  );
};

const DashboardLayout = () => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const handleRouteChange = () => {
      setLoading(true);
      setTimeout(() => setLoading(false), 0);
    };

    const handlePopState = () => handleRouteChange();
    window.addEventListener('popstate', handlePopState);

    return () => window.removeEventListener('popstate', handlePopState);
  }, []);

  return (
    <Base>
      <Routes>
        <Route index element={<React.Suspense fallback={<div className="progress-bar"></div>}><Dashboard /></React.Suspense>} />
        <Route path="category" element={<React.Suspense fallback={<div className="progress-bar"></div>}><All_Cat /></React.Suspense>} />
        <Route path="milestones/:userId/" element={<React.Suspense fallback={<div className="progress-bar"></div>}><MilesTones/></React.Suspense>} />
        <Route path="logo" element={<React.Suspense fallback={<div className="progress-bar"></div>}><Logo /></React.Suspense>} />
        <Route path="service" element={<React.Suspense fallback={<div className="progress-bar"></div>}><Service /></React.Suspense>} />
        <Route path="slides" element={<React.Suspense fallback={<div className="progress-bar"></div>}><Slides /></React.Suspense>} />
        <Route path="teams" element={<React.Suspense fallback={<div className="progress-bar"></div>}><Teams /></React.Suspense>} />
        <Route path="buy" element={<React.Suspense fallback={<div className="progress-bar"></div>}><Paid /></React.Suspense>} />
        <Route path="new-project" element={<React.Suspense fallback={<div className="progress-bar"></div>}><NewWork /></React.Suspense>} />
        <Route path="hired" element={<React.Suspense fallback={<div className="progress-bar"></div>}><Hired /></React.Suspense>} />
        <Route path="paid" element={<React.Suspense fallback={<div className="progress-bar"></div>}><Purchase /></React.Suspense>} />
        <Route path="working" element={<React.Suspense fallback={<div className="progress-bar"></div>}><Working /></React.Suspense>} />
        <Route path="feedback" element={<React.Suspense fallback={<div className="progress-bar"></div>}><Rate /></React.Suspense>} />
        <Route path="tickets" element={<React.Suspense fallback={<div className="progress-bar"></div>}><Tickets /></React.Suspense>} />
        <Route path="ticket" element={<React.Suspense fallback={<div className="progress-bar"></div>}><Ticket /></React.Suspense>} />
        <Route path="users" element={<React.Suspense fallback={<div className="progress-bar"></div>}><Users /></React.Suspense>} />
        <Route path="make" element={<React.Suspense fallback={<div className="progress-bar"></div>}><Make /></React.Suspense>} />
        <Route path="notification" element={<React.Suspense fallback={<div className="progress-bar"></div>}><MakeMoney /></React.Suspense>} />
        <Route path="exchange" element={<React.Suspense fallback={<div className="progress-bar"></div>}><Exchange /></React.Suspense>} />
        <Route path="payout-list/:Id/" element={<React.Suspense fallback={<div className="progress-bar"></div>}><UserMiles/></React.Suspense>} />
      </Routes>
    </Base>
  );
};

export default App;
