import { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
const Footer = () => {
    const [darkMode, setDarkMode] = useState(() => {
        const storedMode = localStorage.getItem('darkMode');
        return storedMode ? JSON.parse(storedMode) : false; 
    });
    useEffect(() => {
        const handleStorageChange = () => {
            const savedMode = localStorage.getItem('darkMode');
            setDarkMode(savedMode ? JSON.parse(savedMode) : false);
        };
        window.addEventListener('storage', handleStorageChange);

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);
    const primaryColor = darkMode ? 'bg-dark text-wheat' : 'bg-dark text-light';
    const textColor = darkMode ? 'text-wheat' : 'text-light';
    return (
        <footer className={`${primaryColor} ${textColor} p-3 text-center`}>
            <div className="row justify-content-center m-2 gap-10">
            <Link to="/privacy-and-policy" className={`${primaryColor}`}>
            <span>Privacy & Policy | </span>
            </Link>
            <Link to="/refund-policy" className={`${primaryColor}`}>
            <span>Refund Policy | </span>
            </Link>
            <Link to="/terms-and-conditions" className={`${primaryColor}`}>
            <span>Terms & conditions</span>
            </Link>
            </div>
            <p className="fs-1">&copy; 2024 AppMars Software Solutions. All rights reserved.</p>
            <p className="fs-08">Developed by: Er.Vikas</p>
        </footer>
    );
};

export default Footer;
