import React, { useState, useEffect } from 'react';

const Contact = () => {
  const [darkMode, setDarkMode] = useState(() => {
    const storedMode = localStorage.getItem('darkMode');
    return storedMode ? JSON.parse(storedMode) : false; 
  });
  useEffect(() => {
    const handleStorageChange = () => {
        const savedMode = localStorage.getItem('darkMode');
        setDarkMode(savedMode ? JSON.parse(savedMode) : false);
    };
    window.addEventListener('storage', handleStorageChange);
  
    return () => {
        window.removeEventListener('storage', handleStorageChange);
    };
  }, []);
  return (
    <div className={`${darkMode ? 'bg-or-dark':'bg-or-light'} order-now-section`}>
    <div className="order-now-content">
      <h1>Contact Us</h1>
      <p>
        You can contact us through the following methods:
      </p>
      <ul>
        <li className='d-block'>Email: appmarsofficial@gmail.com</li>
        <li className='d-block'>Phone: +918565067438</li>
        <li className='d-block'>Alternative Phone: +918756257789</li>
        <li className='d-block'>Address: Sarsaul Block , Kanpur Nagar, India</li>
      </ul>
    </div>
    </div>
  );
};

export default Contact;
